<template>
  <div>
    <div
      v-for="(input, key) in input"
      :key="key"
      class="p-0 border-0 bg-transparent text-left"
    >
      <b-card class="my-4 w-100 plan-card">
        <!-- disabledDiv: input.planId === 2 -->
        <div class="border-bottom p-0 plan-box-title">
          <h4>{{ input.title }}</h4>

          <div class="recomended-badge" v-show="input.planId === 2">
            {{ $t("listing.label.recommended") }}
          </div>
          <div class="text-center">
            <h3 class="price">
              <!-- {{ input.fee }} -->
              {{ currencyFormatter(input.fee) }}
            </h3>
          </div>
        </div>

        <div class="px-2 py-4">
          <section>
            <p class="font-weight-bold">
              {{ input.subtitle }}
            </p>
            <ul>
              <li
                v-for="(desc, key) in input.description.split('/')"
                :key="key"
                class="item"
              >
                {{ desc }}
              </li>
            </ul>
          </section>

          <div
            class="text-center mt-3 px-4"
            style="position: absolute; bottom: 30px; width: 100%; right: 0"
          >
            <b-button
              v-if="!$store.getters.listingAgreement"
              @click="planID === 1 ? openAgreement() : gotoListing(planID)"
              class="px-4 start-btn"
              >{{$t('ListingProcess.agreementModalGetStarted')}}</b-button>
            <b-button
              v-else-if="$store.getters.listingAgreement"
              class="px-4 start-btn"
              @click="selectPlan(input.planId), closepop()"
              ><span v-if="input.planId === $store.getters.listingPlanID">{{$t('ListingProcess.agreementModalContinue')}}</span>
              <span v-else>{{$t('ListingProcess.agreementModalChangePlan')}}</span></b-button
            >
          </div>
        </div>
      </b-card>
    </div>

    <b-modal :id="modalID" hide-footer hide-header centered>
      <div class="text-center">
        <p class="mb-4">
          {{$t('ListingProcess.agreementModalLabel')}}
        </p>
        <b-btn pill variant="primary" class="px-5" @click="gotoListing(1)">{{$t('ListingProcess.agreementModalButton')}}</b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    input: { type: Array },
    selected: { type: Boolean },
    planID: { type: Number },
    selectPlan: { type: Function },
  },
  data() {
    return {
      modalID: "listingAgreement" + this.input[0].planId,
    };
  },

  methods: {
    openAgreement() {
      this.$bvModal.show(this.modalID);
    },
    gotoListing(id) {
      this.selectPlan(id);
      this.$store.dispatch("listingAgreement", true);
      this.$router.push("ListingVehicle");
    },
    currencyFormatter(value) {
      const sc = this.$store.getters.currencyForSeller;
      return new Intl.NumberFormat(this.$store.getters.currencyCulture, {
        style: "currency",
        currency: sc ? sc : "CAD",
        minimumFractionDigits: value % 1 != 0 ? 2 : 0,
      }).format(value);
    },
    closepop() {
      this.$root.$emit("bv::hide::popover");
      this.$root.$emit("bv::toggle::collapse", "planSelector");
    },
    descriptions(text) {
      return text.split(".");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/custom.scss";

.checkfield::before,
.checkfield::after {
  border-bottom-right-radius: 15px;
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}

.checkfield::after {
  content: "\2713";
  font-size: 25px;
  line-height: 25px;
  font-weight: bold;
  color: $white;
}

.checkfield::before {
  border-width: 25px;
  border-right-color: $primary;
  border-bottom-color: $primary;
}

::v-deep .checkmark-line::before {
  content: "\2713";
  margin-right: 0.5em;
  font-size: 25px;
  line-height: 25px;
  font-weight: bold;
  color: $success;
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.1;
}

ul {
  list-style: none;
}

ul li::before {
  content: "\2022";
  color: $primary;
  font-size: 1.3em;
  display: inline-block;
  width: 0.8em;
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
.recomended-badge {
  min-width: 100px;
  font-size: 12px;
  padding: 0 5px;
  height: 22px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fedf01;
  position: absolute;
  top: 24px;
  right: 24px;
  color: #111 !important;
}
.price {
  font-size: 48px;
  font-weight: 600 !important;
  padding-top: 16px;
  padding-bottom: 24px;
}
.item {
  margin-bottom: 24px;
}
.start-btn {
  width: 100%;
  height: 56px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Inter" !important;
  border: 1px solid #006ac3 !important;
  color: #006ac3 !important;
  background: none !important;
  border-radius: 40px;
}
</style>

<style lang="scss">
#listingAgreement1 {
  .modal-content {
    width: 418px;
    margin: auto;
  }
  .modal-body {
    padding: 24px;
  }
  .btn {
    width: 100%;
    height: 48px;
    font-weight: 600;
    font-family: "Inter" !important;
  }
}
</style>
