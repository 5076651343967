<template>
  <div class="mx-auto plan-wrapper">
    <div v-for="(plan, key) in plans" :key="key" class="plan-box">
      <div
        v-bind:class="{ active: selectedPlan === plan[0].planId }"
        @mouseover="
          (selectedPlan = plan[0].planId),
            (selectedPlanName = plan[0].title),
            (selectedPlanPrice = plan[0].fee)
        "
        @mouseleave="selectedPlan = null"
        class="m-auto"
      >
        <TheListingPlanBox
          :input="plan"
          :selected="selectedPlan === plan[0].planId"
          :selectPlan="selectPlan"
          :planID="selectedPlan"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TheListingPlanBox from "@/components/global/TheListingPlanBox";
export default {
  components: {
    TheListingPlanBox,
  },
  data() {
    return {
      selectedPlan: this.$store.getters.listingPlanID,
      selectedPlanName: this.$store.getters.listingPlanName,
      selectedPlanPrice: this.$store.getters.listingPlanPrice,
      agreement: this.$store.getters.listingAgreement,
      plans: this.$store.getters.listingPlan,
    };
  },
  methods: {
    selectPlan(id) {
      this.$store.dispatch("listingPlanID", id);
      this.$store.dispatch("listingPlanName", this.selectedPlanName);
      this.$store.dispatch("listingPlanPrice", this.selectedPlanPrice);
    },
  },
  beforeMount() {
    // this.selectedPlanName = this.plans[
    //   this.$store.getters.listingPlanID - 1
    // ][0].title;
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/custom.scss";

.active {
  box-shadow: 0 0 1rem 0.5rem $gray-200;
  border-radius: 25px;
}
.plan-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
}
.plan-box {
  width: calc(50% - 20px);
}
</style>

<style lang="scss">
.plan-card {
  transition: 0.3s;
  border: 2px solid white !important;
  box-sizing: border-box;
  min-height: 640px;
  border-radius: 4px;
  &:hover {
    box-shadow: -5px 10px 50px rgba(34, 119, 239, 0.15) !important;
    border: 2px solid #006ac3 !important;
  }
}
.plan-box {
  .plan-box-title,
  .price,
  .start-btn {
    transition: 0.3s;
  }
  &:hover {
    .plan-box-title,
    .price {
      color: #006ac3 !important;
    }
    .start-btn {
      background: #006ac3 !important;
      color: white !important;
    }
  }
}
</style>
